import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"

import ImagePropis from "../images/math-cover.jpg"

import russian1 from "../images/math-1.jpg"
import russian2 from "../images/math-2.jpg"
import russian3 from "../images/math-3.jpg"
import OrderMath from "../components/orderMath"

const imgZhi = [
  {
    id: 1,
    pic: russian1,
  },
  {
    id: 2,
    pic: russian2,
  },
  {
    id: 3,
    pic: russian3,
  },
]
const Matematika = () => {
  const imageMap = imgZhi.map(image => {
    return (
      <div key={image.id}>
        <div className="flex justify-center">
          <img className="max-h-350" src={image.pic} alt="some" />
        </div>
      </div>
    )
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Seo title="Прописи математика" />
      <div className="mb-4">
        <a href="/">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          >
            На главную
          </button>
        </a>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <img src={ImagePropis} alt="cover"/>
        </div>
        <div className="pl-0 md:pl-8 md:w-1/2">
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            Прописи математика
          </h1>
          <p className="mt-12 mb-0">
            Рабочая тетрадь по математике предназначена для дошкольников и
            младших школьников, для подготовки руки ребенка к написанию цифр,
            изучения счета, решения примеров на вычитание и сложение. В прописи
            по математике представлены последовательные упражнения.
          </p>
          <br />
          <span className="">
            На первом этапе ребенок чертит и рисует различные фигуры. На втором
            этапе детям предстоит узнать как пишутся цифры и изучить счет. На
            третьем этапе дети будут считать примеры на вычитание и сложение,
            пользоваться математическими знаками больше, меньше или равно.
          </span>
          <br />
          <br />
          <span>
            С помощью этой тетради ребенок научится писать цифры, формировать
            каллиграфические навыки, вырабатывать почерк, развивать мышление,
            интеллект, внимание, память и чистописание. Используя эту тетрадь,
            вы подготовите ребенка к школе, где он будет чувствовать себя
            увереннее с приобретенными знаниями.
          </span>
          <br />
          <br />
          <span>
            Эта пропись станет прекрасным подарком на 1 сентября, день рождения,
            новый год или другой праздник. В рабочей тетради представлены
            задания различной сложности от простого к сложному. Дети научатся
            писать цифры и считать примеры. Все примеры разделены на блоки: счет
            в пределах 10, 20, 30, 40, 50. Пропись по математике станет первым
            помощником в изучении математики.
          </span>
          <br />
          <br />
          <span>Учите правило и тренируйте почерк!!!</span>
        </div>
      </div>
      <OrderMath />
      <div className="mt-24 mb-24">
        <Slider {...settings}>{imageMap}</Slider>
      </div>
    </Layout>
  )
}

export default Matematika
